@import url('https://fonts.googleapis.com/css?family=Dosis&display=swap');

@font-face {
  font-family: 'beattingvile';
  src: url('./fonts/beattingvile/beattingvile-webfont.woff2') format('woff2'),
       url('./fonts/beattingvile/beattingvile-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@media (min-width: 1025px) {
  #root {
    position: absolute;
    left: 25%;
    width: 50%;
    height: 100%;
  }
}

body {
  margin: 0;
  font-family: "Dosis", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: bolder;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(21, 203, 212);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#title_big {
  font-family: 'beattingvile';
  font-weight: normal;
  font-size: 4.5em;
  margin-top: 0.0em;
  margin-bottom: 0.15em;
}

#description {
  margin: 0em 1em 1.3em 1em;
}


