@import url('https://fonts.googleapis.com/css?family=Dosis&display=swap');

select {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-collapse: separate;
    perspective: 1px;
    -moz-appearance: none;
    padding-left: 4em;
    height: 2.3rem;
    width: 100%;
    font-size: .9em;
}

@media (min-width: 1025px) {
    .optionContainer {
        width: 60% !important;
    }
}

.title-box {
    padding-top: 0.5rem;
}

.searchParam {
    background-color: rgb(255, 255, 255);
    border-radius: 0%;
    border-style: none;
    color: #5E5E5E;
    font-family: "Dosis";
    font-weight: bolder;
}

.optionContainer {
    position: relative;
    display: flex;
    width: 80%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.label {
    width: 50%;
    background-color: #14A3AA;
    vertical-align: middle;
    padding-top: 0.5em;
    border-radius: 5px 0 0 5px;
}

.option {
    flex-grow: 1;
    border: 5px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

.searchBtn {
    background-color: #FF735A;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.3em;
    margin-bottom: 3rem;
    margin-top: 0.5rem;
}

.searchBtn:active {
    background-color: rgb(255, 92, 64);
}

.no-highlight {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.chain-option-label {
    /* top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
    margin-left: 0.1rem;
    margin-right: 0.7rem;
    display: inline-block;
    width: 110px;
}

#chain-container {
    margin-top: 0.3rem;
}

.open-option-label {
    /* top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
    margin-left: 0.7rem;
    margin-right: 0.0rem;
    display: inline-block;
    width: 110px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 30px;
    top: 50%;
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #14A3AA;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #14A3AA; 
}

input:focus + .slider {
    box-shadow: 0 0 1px #14A3AA;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border: 0px;
    border-radius: 34px;
}

.slider.round:before {
    border: 0px;
    border-radius: 50%;
}