@font-face {
    font-family: 'beattingvile';
    src: url('../../fonts/beattingvile/beattingvile-webfont.woff2') format('woff2'),
         url('../../fonts/beattingvile/beattingvile-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* a, a:visited, a:active, a:hover, a:focus {
    text-decoration: none;
    color: white;
} */

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    margin-bottom: 0.2em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
}

.header {
    text-align: center;
    font-size: 2.5rem;
}

.section-header {
    padding-top: 1.8rem;
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.2rem;
}

#privacy-content {
    text-align: left;
    padding: 0.2rem 1.5rem 0rem 1.5rem;
    background-color: rgba(255, 255, 255, 0.986);
    margin: 0.4rem;
    border-radius: 10px;
    color: rgb(58, 58, 58);
}

.indent {
    text-indent: 1.2rem;
}

#email-link {
    text-decoration: underline;
    color: rgb(58, 58, 58);
    word-wrap: break-word;
}

#last {
    height: 2.5rem;
}