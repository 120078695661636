@font-face {
    font-family: 'beattingvile';
    src: url('../../fonts/beattingvile/beattingvile-webfont.woff2') format('woff2'),
         url('../../fonts/beattingvile/beattingvile-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

h1 {
    font-weight: bold;
    font-size: 4rem;
    margin-bottom: 2rem;
}

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
}

.description {
    font-size: 1.2rem;
}

.search-btn {
    background-color: #14A3AA;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin-top: 2.5em;
}