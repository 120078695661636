/* a, a:visited, a:active, a:hover, a:focus {
    text-decoration: none;
    color: white;
} */

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    margin-bottom: 0.2em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
}

#page-header {
    text-align: center;
    font-size: 2.5rem;
}

.contact-body {
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.986);
    margin: 0.4rem;
    border-radius: 10px;
    color: rgb(58, 58, 58);
}

#email-link {
    text-decoration: underline;
    color: rgb(58, 58, 58);
}