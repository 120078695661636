@font-face {
    font-family: 'beattingvile';
    src: url('../../fonts/beattingvile/beattingvile-webfont.woff2') format('woff2'),
         url('../../fonts/beattingvile/beattingvile-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    margin-bottom: 0.2em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
}

.restaurant-name {
    font-family: "Dosis";
    font-weight: bolder;
    font-size: 2.1rem;
    margin-top: 0px;
    margin-bottom: 1rem;
}

.restaurant-info {
    font-family: "Dosis";
    font-weight: normal;
    font-size: 1.2em;
    margin: 0.35em;
}

#open-close {
    margin-bottom: 1em;
}

.btn-wrapper {
    display: flex;
    width: 100%;
}

#directions-btn {
    background-color: #14A3AA;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1em;
    /* width: 33.33%; */
    flex: 1
}

#directions-btn:active {
    background-color: rgb(16, 141, 148);
}

#rerun-btn {
    background-color: #FF735A;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1em;
    /* flex-grow: 1; */
    flex: 1;
}

#rerun-btn:active {
    background-color: rgb(255, 92, 64);
}

#search-btn {
    background-color: #14A3AA;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1em;
    /* flex-grow: 1; */
    flex: 1;
}

#search-btn:active {
    background-color: rgb(16, 141, 148);
}

@media (min-width:1025px) {
    #map{
        height:30rem;
        margin-top: 1.5rem;
    }
}

@media (max-width:1025px) {
    #map{
        height:275px;
        width:100%;
    }
}

#content, #content:active, #content:visited, #content:hover, #content:focus {
    color: black;
    text-align: left;
    padding: 0.25rem;
}

#info-restaurant-title {
    font-weight: bolder;
    font-size: 1.2rem;
}

/* a, a:visited, a:focus, a:active, a:hover {
    color: black !important;
} */

/* #makeblack {
    color: black !important;
} */