@import url(https://fonts.googleapis.com/css?family=Dosis&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dosis&display=swap);
@font-face {
  font-family: 'beattingvile';
  src: url(/static/media/beattingvile-webfont.e73ea58f.woff2) format('woff2'),
       url(/static/media/beattingvile-webfont.23d4a401.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@media (min-width: 1025px) {
  #root {
    position: absolute;
    left: 25%;
    width: 50%;
    height: 100%;
  }
}

body {
  margin: 0;
  font-family: "Dosis", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: bolder;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(21, 203, 212);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#title_big {
  font-family: 'beattingvile';
  font-weight: normal;
  font-size: 4.5em;
  margin-top: 0.0em;
  margin-bottom: 0.15em;
}

#description {
  margin: 0em 1em 1.3em 1em;
}



/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 26px;
    height: 20px;
    right: 20px;
    top: 20px;
  }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #14A3AA;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgb(11, 88, 92);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  right: 10px;
  top: 10px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.menu-item {
  color: white;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 0em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8rem 0 0 0;
  text-align: center;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

/* Individual item */
.bm-item, .bm-item:visited, .bm-item:hover, .bm-item:active, .bm-item:focus {
  display: inline-block;
  color: #e9e9e9;
  font-size: 1.4rem;
  padding: 0.4rem 0rem 0.4rem 0rem;
  text-decoration: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  outline: 0;
}

.bm-item:active {
  background-color: #272931;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

button:focus {
  outline:0;
}
/* a, a:visited, a:active, a:hover, a:focus {
    text-decoration: none;
    color: white;
} */

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    margin-bottom: 0.2em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
    color: white;
    text-decoration: none;
}

#page-header {
    text-align: center;
    font-size: 2.5rem;
}

.about-body {
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.986);
    margin: 0.4rem;
    border-radius: 10px;
    color: rgb(58, 58, 58);
}

.section-header {
    padding-top: 1.8rem;
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.2rem;
}

.site-link, .site-link:hover, .site-link:active, .site-link:focus, .site-link:visited {
    color: rgb(58, 58, 58)
}
#loading {
    margin: 4em;
}

#message {
    margin-top: 3em;
    font-size: 1.3rem;
}
@font-face {
    font-family: 'beattingvile';
    src: url(/static/media/beattingvile-webfont.e73ea58f.woff2) format('woff2'),
         url(/static/media/beattingvile-webfont.23d4a401.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

h1 {
    font-weight: bold;
    font-size: 4rem;
    margin-bottom: 2rem;
}

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
}

.description {
    font-size: 1.2rem;
}

.search-btn {
    background-color: #14A3AA;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin-top: 2.5em;
}
select {
    -webkit-appearance: none;
    border-radius: 0px;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-collapse: separate;
    -webkit-perspective: 1px;
            perspective: 1px;
    -moz-appearance: none;
    padding-left: 4em;
    height: 2.3rem;
    width: 100%;
    font-size: .9em;
}

@media (min-width: 1025px) {
    .optionContainer {
        width: 60% !important;
    }
}

.title-box {
    padding-top: 0.5rem;
}

.searchParam {
    background-color: rgb(255, 255, 255);
    border-radius: 0%;
    border-style: none;
    color: #5E5E5E;
    font-family: "Dosis";
    font-weight: bolder;
}

.optionContainer {
    position: relative;
    display: flex;
    width: 80%;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.label {
    width: 50%;
    background-color: #14A3AA;
    vertical-align: middle;
    padding-top: 0.5em;
    border-radius: 5px 0 0 5px;
}

.option {
    flex-grow: 1;
    border: 5px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

.searchBtn {
    background-color: #FF735A;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.3em;
    margin-bottom: 3rem;
    margin-top: 0.5rem;
}

.searchBtn:active {
    background-color: rgb(255, 92, 64);
}

.no-highlight {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.chain-option-label {
    /* top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
    margin-left: 0.1rem;
    margin-right: 0.7rem;
    display: inline-block;
    width: 110px;
}

#chain-container {
    margin-top: 0.3rem;
}

.open-option-label {
    /* top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
    margin-left: 0.7rem;
    margin-right: 0.0rem;
    display: inline-block;
    width: 110px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 30px;
    top: 50%;
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #14A3AA;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #14A3AA; 
}

input:focus + .slider {
    box-shadow: 0 0 1px #14A3AA;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border: 0px;
    border-radius: 34px;
}

.slider.round:before {
    border: 0px;
    border-radius: 50%;
}
@font-face {
    font-family: 'beattingvile';
    src: url(/static/media/beattingvile-webfont.e73ea58f.woff2) format('woff2'),
         url(/static/media/beattingvile-webfont.23d4a401.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    margin-bottom: 0.2em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
}

.restaurant-name {
    font-family: "Dosis";
    font-weight: bolder;
    font-size: 2.1rem;
    margin-top: 0px;
    margin-bottom: 1rem;
}

.restaurant-info {
    font-family: "Dosis";
    font-weight: normal;
    font-size: 1.2em;
    margin: 0.35em;
}

#open-close {
    margin-bottom: 1em;
}

.btn-wrapper {
    display: flex;
    width: 100%;
}

#directions-btn {
    background-color: #14A3AA;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1em;
    /* width: 33.33%; */
    flex: 1 1
}

#directions-btn:active {
    background-color: rgb(16, 141, 148);
}

#rerun-btn {
    background-color: #FF735A;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1em;
    /* flex-grow: 1; */
    flex: 1 1;
}

#rerun-btn:active {
    background-color: rgb(255, 92, 64);
}

#search-btn {
    background-color: #14A3AA;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1em;
    /* flex-grow: 1; */
    flex: 1 1;
}

#search-btn:active {
    background-color: rgb(16, 141, 148);
}

@media (min-width:1025px) {
    #map{
        height:30rem;
        margin-top: 1.5rem;
    }
}

@media (max-width:1025px) {
    #map{
        height:275px;
        width:100%;
    }
}

#content, #content:active, #content:visited, #content:hover, #content:focus {
    color: black;
    text-align: left;
    padding: 0.25rem;
}

#info-restaurant-title {
    font-weight: bolder;
    font-size: 1.2rem;
}

/* a, a:visited, a:focus, a:active, a:hover {
    color: black !important;
} */

/* #makeblack {
    color: black !important;
} */
@font-face {
    font-family: 'beattingvile';
    src: url(/static/media/beattingvile-webfont.e73ea58f.woff2) format('woff2'),
         url(/static/media/beattingvile-webfont.23d4a401.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

h1 {
    font-weight: bold;
    font-size: 4rem;
    margin-bottom: 2rem;
}

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
}

.description {
    font-size: 1.2rem;
}

.search-btn {
    background-color: #14A3AA;
    font-family: "Dosis";
    font-weight: bold;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 0.8em 1.6em;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin-top: 2.5em;
}
.App {
  text-align: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* a, a:visited, a:active, a:hover, a:focus {
    text-decoration: none;
    color: white;
} */

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    margin-bottom: 0.2em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
}

#page-header {
    text-align: center;
    font-size: 2.5rem;
}

.contact-body {
    padding: 0.2rem 1.5rem 0.2rem 1.5rem;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.986);
    margin: 0.4rem;
    border-radius: 10px;
    color: rgb(58, 58, 58);
}

#email-link {
    text-decoration: underline;
    color: rgb(58, 58, 58);
}
@font-face {
    font-family: 'beattingvile';
    src: url(/static/media/beattingvile-webfont.e73ea58f.woff2) format('woff2'),
         url(/static/media/beattingvile-webfont.23d4a401.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

/* a, a:visited, a:active, a:hover, a:focus {
    text-decoration: none;
    color: white;
} */

.app-header {
    margin: 0%;
    margin-top: 0.4em;
    margin-left: 0.4em;
    margin-bottom: 0.2em;
    text-align: left;
    font-family: "beattingvile";
    font-weight: normal;
    font-size: 1.5em;
}

.header {
    text-align: center;
    font-size: 2.5rem;
}

.section-header {
    padding-top: 1.8rem;
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.2rem;
}

#privacy-content {
    text-align: left;
    padding: 0.2rem 1.5rem 0rem 1.5rem;
    background-color: rgba(255, 255, 255, 0.986);
    margin: 0.4rem;
    border-radius: 10px;
    color: rgb(58, 58, 58);
}

.indent {
    text-indent: 1.2rem;
}

#email-link {
    text-decoration: underline;
    color: rgb(58, 58, 58);
    word-wrap: break-word;
}

#last {
    height: 2.5rem;
}
