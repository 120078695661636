/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 26px;
    height: 20px;
    right: 20px;
    top: 20px;
  }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #14A3AA;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgb(11, 88, 92);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  right: 10px;
  top: 10px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.menu-item {
  color: white;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 0em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8rem 0 0 0;
  text-align: center;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

/* Individual item */
.bm-item, .bm-item:visited, .bm-item:hover, .bm-item:active, .bm-item:focus {
  display: inline-block;
  color: #e9e9e9;
  font-size: 1.4rem;
  padding: 0.4rem 0rem 0.4rem 0rem;
  text-decoration: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  outline: 0;
}

.bm-item:active {
  background-color: #272931;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

button:focus {
  outline:0;
}